import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Parallax } from 'react-scroll-parallax';


//import { Link } from "../utils/link"
import { Link } from "gatsby"
import Fade from 'react-reveal/Fade'

import Seo from '../components/seo'

import Logo from '../assets/images/logo-black.svg'
import Badge from '../assets/images/ico-badge.inline.svg'
import Grid from '../assets/images/ico-grid.inline.svg'

class PageTemplate extends Component {

  _toggleOffCanvas = () => {
    //this.setState({ offCanvas: !this.state.offCanvas })
    const box = document.getElementById('landing');
    box.classList.add("hidden");
  }

  render() {
    let page = this.props.data.wpPage

    function hoverByClassAbout(classname,colorover,colorout="1"){
      var elms=document.getElementsByClassName(classname);
      var container = document.getElementById('list');
      for(var i=0;i<elms.length;i++){
        elms[i].onmouseover = function(){
          for(var k=0;k<elms.length;k++){
            elms[k].classList.add("activeItems");
            container.classList.add("hover");
          }
        };
        elms[i].onmouseout = function(){
          for(var k=0;k<elms.length;k++){
            elms[k].classList.remove("activeItems");
            container.classList.remove("hover");
          }
        };
      }
    }

    function hoverByClassFootball(classname,colorover,colorout="1"){
      var elms=document.getElementsByClassName(classname);
      var container = document.getElementById('list');
      for(var i=0;i<elms.length;i++){
        elms[i].onmouseover = function(){
          for(var k=0;k<elms.length;k++){
            elms[k].classList.add("activeItems");
            container.classList.add("hover");
          }
        };
        elms[i].onmouseout = function(){
          for(var k=0;k<elms.length;k++){
            elms[k].classList.remove("activeItems");
            container.classList.remove("hover");
          }
        };
      }
    }

    function hoverByClassConcepts(classname,colorover,colorout="1"){
      var elms=document.getElementsByClassName(classname);
      var container = document.getElementById('list');
      for(var i=0;i<elms.length;i++){
        elms[i].onmouseover = function(){
          for(var k=0;k<elms.length;k++){
            elms[k].classList.add("activeItems");
            container.classList.add("hover");
          }
        };
        elms[i].onmouseout = function(){
          for(var k=0;k<elms.length;k++){
            elms[k].classList.remove("activeItems");
            container.classList.remove("hover");
          }
        };
      }
    }

    if (typeof window !== 'undefined') {
      setTimeout(() => {
        hoverByClassAbout("about");
        hoverByClassFootball("football");
        hoverByClassConcepts("concepts");

        // const box = document.getElementById('landing');
        // box.classList.add("hidden");
      }, 100);
    }

    return (
      <>
        <Seo title={he.decode(page.seo.title)} bodyClass={page.slug} description={page.seo.metaDesc} />

        <div className='home-list__logo'>
          <div className='home-list__logo--inner'>
            {/* <img className='large' src={Logo} alt='Divock Origi' /> */}
          </div>
        </div>

        {/* <section id='landing' className="landing">
          <div className='landing__inner'>
             <Fade distance={'40px'} delay={500}>
                <img onClick={this._toggleOffCanvas} className='landing__logo' src={Logo} alt='Divock Origi' />
            </Fade>
          </div>
        </section> */}

        <section id="list" className="home-list">
        { page.acf.homeImages && page.acf.homeImages.map((el, i) => (
            <div key={i} className='home-list__item'>

                {i === 2 &&
                  <div className='home-list__ico-badge'><Badge /></div>
                }

                {i === 3 &&
                  <div className='home-list__ico-badge two'><Badge /></div>
                }

                {i === 3 &&
                  <div className='home-list__ico-grid one'><Grid /></div>
                }

                {i === 8 &&
                  <div className='home-list__ico-badge three'><Badge /></div>
                }

                {i === 10 &&
                  <div className='home-list__ico-grid'><Grid /></div>
                }

                {i === 12 &&
                  <div className='home-list__ico-badge four'><Badge /></div>
                }

                {i === 1 &&
                  <div className={'item__' + i }>
                    <Link to={'/'+el.page}>
                      <Parallax translateY={[-15, 20]}>
                        <div className='first-img'>

                          <img className='logo-black' src={Logo} alt='Divock Origi' />
                          <GatsbyImage className={'home-list__image ' + el.page} loading='lazy' image={getImage(el.image?.localFile)} alt={el.page} />
                        </div>
                      </Parallax>
                    </Link>
                  </div>
                }

              {i !== 1 &&
                <div className={'item__' + i }>
                  <Link to={'/'+el.page}>
                    <Parallax translateY={[-15, 20]}>
                      <GatsbyImage className={'home-list__image ' + el.page} loading='lazy' image={getImage(el.image?.localFile)} alt={el.page} />
                    </Parallax>
                  </Link>
                </div>
              }

            </div>
          )) }
          </section>

      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      slug
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        homeImages {
          fieldGroupName
          page
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 75
                  width: 1800
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
